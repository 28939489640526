import * as React from "react";
import { IFImg } from "@libs/utils/IFImg";
import { toJpDate, toJpTime } from "@libs/utils/date";
import { shouldShowMatchTime } from "@libs/utils/match";
import styles from "./index.module.css";
import type { ShopCardListItem_MatchListItemFragment as MatchListItemFragment } from "../__generated__/fragments";

type MatchBadgeProps = {
  match: MatchListItemFragment;
};
const MatchBadge: React.FC<MatchBadgeProps> = ({ match }) => {
  const date = toJpDate(match?.startsAt).toUpperCase();

  let time = match?.startsAt ? toJpTime(match?.startsAt).toUpperCase() : "";
  if (!shouldShowMatchTime(match)) {
    time = "時間未定";
  }

  return (
    <div>
      {match.contentsHolderLogoPath && (
        <div className={styles.daznLogo}>
          <img src={IFImg(match.contentsHolderLogoPath)} alt="" />
        </div>
      )}

      <div className={styles.match}>
        {match.label && (
          <div className={styles.matchGenreLabel}>{match.label}</div>
        )}
        <div className={styles.matchTournamentName}>
          {match.masterTournament?.name ?? ""}
        </div>
        <div className={styles.matchTeam}>
          <span className={styles.teamName}>
            <span className={styles.teamIcon}>
              <img src={IFImg(match.homeTeamIconPath)} alt="" />
            </span>
            {match.homeTeamDisplayShortName}
          </span>
          <span className={styles.vs}>vs</span>
          <span className={styles.teamName}>
            {match.awayTeamDisplayShortName}
            <span className={styles.teamIcon}>
              <img src={IFImg(match.awayTeamIconPath)} alt="" />
            </span>
          </span>
        </div>
        <div className={styles.matchDate}>
          {date} {time} 試合開始
        </div>
      </div>
    </div>
  );
};
export default MatchBadge;
