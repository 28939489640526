import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.css";

type Props = {
  rating?: number;
  ratingCount?: number;
  shouldShowRating: boolean;
  rateCountSmall?: boolean;
};

const ShopRating: React.FC<Props> = ({
  rating = 0.0,
  ratingCount = 0,
  shouldShowRating,
  rateCountSmall,
}) => {
  const ratingStr = rating.toFixed(1);
  const [firstDigit, firstDecimal] = ratingStr.split(".");
  const starClassName = shouldShowRating
    ? firstDecimal === "0"
      ? `star${firstDigit}`
      : `star${firstDigit}_${firstDecimal}`
    : "star0";
  const ratingToShow = shouldShowRating ? ratingStr : "--";

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.star, styles[starClassName])} />
      <div className={styles.rate}>
        <span className={styles.rateNumber}>{ratingToShow}</span>
        <span
          className={classNames(styles.rateCount, [
            rateCountSmall ? styles.small : "",
          ])}
        >
          ({ratingCount.toLocaleString()})
        </span>
      </div>
    </div>
  );
};

export default ShopRating;
