import * as React from "react";
import type { UsersCampaignLabelType } from "@graphql/__generated__/types";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";

type Props = {
  campaignLabels: Array<
    Pick<UsersCampaignLabelType, "iconPath" | "displayText">
  >;
};

const ShopCampaignLabel: React.FC<Props> = ({ campaignLabels }) => {
  return (
    <ul className={styles.shopCampaignLabelList}>
      {campaignLabels.map((label, index) => {
        return (
          <li className={styles.shopCampaignLabelItem} key={index}>
            {label.iconPath && (
              <img
                src={IFImg(label.iconPath)}
                className={styles.shopCampaignLabelIcon}
                alt=""
              />
            )}
            {label.displayText}
          </li>
        );
      })}
    </ul>
  );
};

export default ShopCampaignLabel;
