import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import { ImageFlux } from "@components/ImageFlux";
import ShopCampaignLabel from "@components/ShopCampaignLabel";
import ShopRating from "@components/ShopRating";
import { Text } from "@components/Text";
import { paths } from "@libs/paths";
import MatchBadge from "../MatchBadge";
import styles from "./index.module.css";
import type { ShopCardSmall_ShopListItemFragment as ShopListItemFragment } from "./__generated__/fragments";
import type { ShopCardListItem_MatchListItemFragment as MatchListItemFragment } from "../__generated__/fragments";

type Props = {
  shop: ShopListItemFragment;
  customDisplayMatch?: MatchListItemFragment;
  headingTag?: boolean;
  onClick?: () => void;
  slider?: boolean;
  fixedWidth?: boolean;
};

export const ShopCardSmall: React.FC<Props> = ({
  shop,
  customDisplayMatch,
  headingTag,
  onClick,
  slider,
  fixedWidth,
}) => {
  const match = customDisplayMatch || shop.latestMatch;

  const firstImage = shop.thumbnailImages[0];
  const imagePath = firstImage ? firstImage.imageUrl : "";

  return (
    <div
      key={shop.uuid}
      className={classNames(
        styles.shopCard,
        { [styles.sliderCard]: slider },
        { [styles.fixedWidth]: fixedWidth }
      )}
      onClick={onClick}
    >
      <Link href={paths.shopDetail(shop.uuid)} className={styles.link}>
        <dl className={styles.detail}>
          <dd className={classNames(styles.detailImage)}>
            <div className={styles.detailImageWrap}>
              <ImageFlux imageType={"shop"} url={imagePath} alt={""} />
            </div>

            {match && <MatchBadge match={match} />}
          </dd>
          <dt className={styles.detailTitle}>
            {headingTag ? (
              <div>
                <Text tag="h3" size={18} bold>
                  {shop.name}
                </Text>
              </div>
            ) : (
              shop.name
            )}
          </dt>
          <dd className={styles.detailText}>
            <div className={styles.stationContainer}>
              <span className={styles.stationName}>{shop.closestStation}</span>
              <span>駅 徒歩{shop.closestStationTime}分</span>
            </div>
          </dd>
          {shop.shopRating?.shouldShowRating && (
            <dd className={styles.shopRating}>
              <ShopRating
                rating={shop.shopRating?.ratingCalculated}
                ratingCount={shop.shopRating?.ratingCount}
                shouldShowRating={true}
                rateCountSmall
              />
            </dd>
          )}
          <dd className={styles.campaignLabels}>
            <ShopCampaignLabel campaignLabels={shop.campaignLabels} />
          </dd>
        </dl>
      </Link>
    </div>
  );
};
