import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import { ImageFlux } from "@components/ImageFlux";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";
import type { NewsCard_NewsArticleListItemFragment as NewsArticleListItemFragment } from "./__generated__/fragments";

type Props = {
  newsArticle: NewsArticleListItemFragment;
  portrait?: boolean;
  landscapeSmall?: boolean;
  onClick?: () => void;
  openNewTab?: boolean;
  showLabels?: boolean;
};

export const NewsCard: React.FC<Props> = ({
  newsArticle,
  portrait,
  landscapeSmall,
  onClick,
  openNewTab = false,
  showLabels = true,
}) => {
  const { allTeams } = TeamsByTournamentState.useContainer();
  const teamId = newsArticle.masterTeam?.id;
  const team = allTeams.find((t) => t.id === teamId);

  return (
    <Link
      href={paths.newsArticle(newsArticle.id.toString())}
      className={classNames(styles.articleCard, {
        [styles.portraitCard]: portrait,
        [styles.landscapeSmall]: landscapeSmall,
      })}
      target={(openNewTab && "_blank") || undefined}
      onClick={() => onClick && onClick()}
    >
      <div className={styles.articleThumbnail}>
        <ImageFlux
          imageType="news"
          url={newsArticle.imageUrl}
          alt={newsArticle.imageAlt}
        />
      </div>
      <div className={styles.articleInfo}>
        <div className={styles.articleTitle}>{newsArticle.title}</div>
        {showLabels && (
          <div className={styles.articleCategory}>
            <span className={styles.date}>{newsArticle.passedTimeLabel}</span>
            {team && (
              <span className={styles.team}>
                <span className={styles.teamLogo}>
                  <img src={IFImg(team.iconPath)} alt={team.name} />
                </span>
                <span className={styles.teamName}>{team.shortName}</span>
              </span>
            )}
          </div>
        )}
      </div>
    </Link>
  );
};
